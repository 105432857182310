<template>
  <div class="home">
    <el-container>
    
         <el-header
        v-if="offsetwidth > 1005"
       style="background-color: white; width: 100%;height: 130px;"
      >
    
            <div style="display:flex;justify-content: center;">
             <img
              style="height: 35px;margin-top:10px"
              src="../assets/imgThree/PC/logo.png"
              alt=""
            />
          </div>
          <div style="height:5px;display: flex;
    justify-content: center;margin-top:5px">
          <div style="border-bottom: 1px solid #c8cccf;margin-left:10px" :class="offsetwidth>1500?'wg_65':'wg_80'"></div></div>
        <el-menu
          router
          :default-active="activeIndex"
          background-color="white"
          text-color="black"
          active-text-color="#0250A4"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          style="display:flex;justify-content: center;border-right: none;margin-top:5px"
        >
        
           <el-menu-item  index="/index" 
            >Home</el-menu-item
          >
          <el-menu-item  index="/AboutUs"  style="margin-left:3%"
            >About Us</el-menu-item
          >
          <el-menu-item  index="/Products"  style="margin-left:3%"
            >Products</el-menu-item
          >
          <el-menu-item index="/gallery"  style="margin-left:3%"
            >Gallery</el-menu-item
          >
          <el-menu-item  index="/ContactUs"  style="margin-left:3%"
            >Contact Us</el-menu-item
          >
          <el-menu-item  index="/CustomerDetails"  style="margin-left:3%"
            >Join Now</el-menu-item
          >
          <el-menu-item  index="/privacyPolicy"  style="margin-left:3%"
            >Privacy Policy</el-menu-item
          >
           <el-menu-item  index="/terms"  style="margin-left:3%"
            >Terms and conditions</el-menu-item
          >
        </el-menu>
      </el-header>

      <el-header v-else  style="background-color: white; width: 100%">
        <el-menu
          router
          :default-active="activeIndex"
          background-color="white"
          text-color="black"
     active-text-color="white"
          class="el-menu-demo"
          @select="handleSelect"
        >
          <div style="float: left">
            
           
             <img
              style="height: 46px;margin-top:5px"
              src="../assets/imgThree/H5/logonew.jpg"
              alt=""
            />
             
          </div>
          
          <div
            @click="showmenu = !showmenu"
            style="display:flex;background-color: white; padding:5px;0;border-radius:5px;position:absolute;top:10px;right:10px;"
          >
          <span style="margin-top:2px;margin-right: 5px;">Menu</span>
            <img src="../assets/imgThree/H5/menu1.png" style="width: 30px;color:red" />
          </div>
          <el-menu-item v-show="showmenu" index="/index">Home</el-menu-item>
          <el-menu-item v-show="showmenu" index="/AboutUs"
            >About Us</el-menu-item
          >
          <el-menu-item v-show="showmenu" index="/Products"
            >Products</el-menu-item
          >
          <el-menu-item v-show="showmenu" index="/gallery"
            >Gallery</el-menu-item
          >
          <el-menu-item v-show="showmenu" index="/ContactUs"
            >Contact Us</el-menu-item
          >
          <el-menu-item v-show="showmenu" index="/CustomerDetails"
            >Join Now</el-menu-item
          >
          <el-menu-item v-show="showmenu" index="/privacyPolicy"
            >Privacy Policy</el-menu-item
          >
           <el-menu-item  v-show="showmenu" index="/terms" 
            >Terms and conditions</el-menu-item
          >
        </el-menu>
      </el-header>
      <!-- <el-main> -->
      <div style="width: 100%" class="flex_around">
        <router-view
          style="width: 100%"
          @fun="getinfo"
          :offsetwidth="offsetwidth"
        ></router-view>
      </div>
      <!-- </el-main> -->
      <!-- <el-footer>Footer</el-footer> -->
    </el-container>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "Home",
  components: {
    // HelloWorld
  },
  data() {
    return {
      showmenu: false,
      activeIndex: "/index",
      offsetwidth: document.documentElement.clientWidth,
    };
  },
  // computed: {
  //   activeIndex() {
  //     return window.sessionStorage.getItem("activeIndex");
  //   },
  // },
  watch: {
    // 如果 `offset ` 发生改变，这个函数就会运行
    offsetwidth: function (val) {
      if (!this.timer) {
        this.offsetwidth = val;
        // this.showmenu = this.offsetwidth > 700;
        // this.changeheight(val);
        this.timer = true;
        let that = this;
        let timeout = setTimeout(function () {
          // clearTimeout(timeout);/
          //频繁触发 resize 函数，会导致页面很卡
          that.timer = false;
        }, 100);
      }
    },
  },
  mounted() {
    
    window.sessionStorage.getItem("activeIndex")
      ? (this.activeIndex = window.sessionStorage.getItem("activeIndex"))
      : "";
    let that = this;
    window.addEventListener("resize", () => {
      window.offsetwidth = document.documentElement.clientWidth;
      that.offsetwidth = window.offsetwidth;
    });
    // router.beforeEach((to, from, next) => {});
  },
  methods: {
    getinfo(data) {
      this.activeIndex = data;
      window.sessionStorage.setItem("activeIndex", "/CustomerDetails");

      this.$router.push("/CustomerDetails");
    },
    handleSelect(key, keyPath) {
      window.sessionStorage.setItem("activeIndex", key);
      this.activeIndex = key;
      this.showmenu = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.el-header {
  z-index: 10;
  ul {
    margin-left: 0 !important;
    display: contents;
  }
}
.flex_around {
  display: flex;
  justify-content: space-around;
}
@media screen and (max-width: 920px) {
  .el-menu {
    z-index: 99;
    list-style: none;
    position: relative;
    margin: 0;
    padding-left: 0;
    background-color: #283144;
    justify-content: space-around;
  }
  .el-menu-item {
    font-size: 14px;
    text-align: left;
    color: #303133;
    padding: 0 12px;
    cursor: pointer;
    transition: border-color 0.3s, background-color 0.3s, color 0.3s;
    box-sizing: border-box;
  }
}

@media screen and (min-width: 919px) {
  .el-menu {
    border-right: solid 1px #e6e6e6;
    list-style: none;
    position: relative;
    margin: 0;
    padding-left: 0;
    background-color: #fff;
  }
}
.el-button {
  color: #fff;
  background-color: #545c64;
  border: 1px solid #fff;
  font-weight: 700;
}
.content {
  height: calc(100vh - 60px);
}
.el-menu.el-menu--horizontal {
  border-bottom: none;
}
.el-menu-item {
 background-color: white !important;
  font-size:15px;
  font-weight: 700;
}
.el-menu-item.is-active {
    color: #0250A4 !important;
  background-color:white !important;
  border-bottom-color:#0250A4 !important;
}

.el-menu-item:hover {
  color: #0250A4 !important;
   background-color: white  !important;
   border-bottom-color:#0250A4 !important;

}
.wg_80{
  width:80%;
}
.wg_65{
  width:65%;
}
</style>
