import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token:''
  },
  mutations: {
    setToken (state,token) {
			state.token =token;
			sessionStorage.setItem("token",token);
		},
		delToken (state) {
			state.token = '';
			sessionStorage.removeItem("token");
		}
  },
  actions: {
  },
  modules: {
  }
})
