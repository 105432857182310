import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
// import '../src/assets/sass/global.css'
Vue.config.productionTip = false
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "../node_modules/element-ui/lib/locale/lang/en";
// import './assets/api/directive'
Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = el.dataset.title
  }
})

// 添加请求拦截器
// axios.interceptors.request.use(
//   (config) => {
//     // 在发送请求之前做些什么
//     //判断是否存在token，如果存在将每个页面header都添加token
//     // console.log(store.state.token);

//     // if (store.state.token) {
//     //   // config.headers.common['token']=store.state.token
//     // console.log(store.state.token);

//     //   config.headers.auth = store.state.token;
//     // }
//     if ( store.state.token) {
//       config.headers.token = store.state.token;
//     }

//     return config;
//   },
//   (error) => {
//     // 对请求错误做些什么
//     return Promise.reject(error);
//   }
// );
Vue.use(ElementUI, {
  locale,
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')